@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700&display=swap');

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// input:-webkit-autofill:active {
//   transition: background-color 5000s ease-in-out 0s !important;
// }
// body {
//   margin: 0;
//   font-family: 'Lato', sans-serif !important;

//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;

//   scrollbar-width: thin; /*thin;*/
//   scrollbar-color: #fb7c51b7 #ffffff;

//   &::-webkit-scrollbar {
//     width: 7.5px;
//   }
//   &::-webkit-scrollbar-track {
//     background: #ffffff; /* color of the tracking area */
//   }

//   &::-webkit-scrollbar-thumb {
//     background-color: #fb7c51b7; /* color of the scroll thumb */
//     border-radius: 2px; /* roundness of the scroll thumb */
//     /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
//   }

.scroller {
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #ed2c94 #ffffff;
}
.scroller::-webkit-scrollbar {
  width: 7.5px;
}
.scroller::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #ed2c94; /* color of the scroll thumb */
  border-radius: 2px; /* roundness of the scroll thumb */
  /* border: 1px solid #000066;  */ /* creates padding around scroll thumb */
}

.message-input {
  .scroller {
    scrollbar-width: thin; /*thin;*/
    scrollbar-color: #ed2c94 #f3f3f3;
  }

  .scroller::-webkit-scrollbar-track {
    background: #f3f3f3; /* color of the tracking area */
  }
}

// text gradient
.text-gradient {
  background: linear-gradient(139.06deg, #9a73e1 -0.01%, #fa67b6 100.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-gradient2 {
  background: linear-gradient(90deg, #a200ca 9.9%, #e200aa 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

// gradient border

.border-gradient {
  border-image-slice: 1;
  border-image-source: linear-gradient(90deg, #a200ca -33.05%, #e200aa 145%);
}

//   // .bgOpacity {
//   //   opacity: 0.5;
//   // }
// }

// .slick-slide {
//   padding: 0px 10px;
// }

.PricingPlans {
  .widthOneFive {
    width: 19.2%;
  }
  .widthOne {
    width: 15.35%;
  }
}

@import url('https://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css2?family=Covered+By+Your+Grace&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'another';
  src: url('assets/fonts/CoveredByYourGrace-Regular.ttf');
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.font-en-lato {
  font-family: 'lato', sans-serif;
}

.font-ar-baloo {
  font-family: 'Baloo Bhaijaan 2', sans-serif;
}

h2 {
  color: #000;
}

#swiper-color .swiper-button-next {
  color: #ed2c94;
}

#swiper-color .swiper-button-prev {
  color: #ed2c94;
}

#swiper-color .swiper-pagination-bullet-active {
  background-color: #ed2c94;
}

.text_slide {
  font-family: 'Covered By Your Grace', cursive;
  font-style: normal;
  font-weight: normal;
  font-size: 39.4714px;
  line-height: 53px;
}
.hero-bg {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('assets/images/hero-bg.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100vw;
  height: 700px;
}
.hero-bg-ar {
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url('assets/images/hero-bg-ar.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 100vw;
  height: 700px;
}

.hero-resources-bg-mobile {
  background: url('assets/images/hero-resources-bg.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100vw;
  height: 700px;
}

.hero-resources-bg {
  background: url('assets/images/hero-resources-bg.svg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  width: 100vw;
  height: 425px;
}

.border-gradient-bottom {
  box-shadow: 0px 3.9996px 3.9996px rgba(134, 134, 134, 0.2);
}

.apps {
  background: url('assets/images/Vector 1891.png');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
}

.list_container {
  height: 65px;
  z-index: -1;
  overflow: hidden;
}

.parallax {
  transform: translateZ(0px);
  overflow-x: hidden;
  overflow-y: scroll;
  perspective: 8px;
  perspective-origin: 0%;
  display: flex;
}
.parallax img {
  margin-top: auto;
  margin-bottom: 50px;

  transform-origin: 0;
  transform: translateZ(3px);
}

.slider {
  width: 70%;

  overflow: visible;
  margin-top: 50px;
}

@media (max-width: 768px) {
  .slider {
    width: 100%;
  }

  .text_slide {
    font-family: 'another';
    font-size: 40px;
    font-style: italic;
  }
}
.slider ul {
  display: flex;
  padding: 0;

  background-color: #f7f8fb;
  height: 100px;
  width: 100%;
  justify-content: space-between;
}
.slider li {
  padding: 10px;
  list-style: none;
  margin-top: 30px;
}
.slider img {
  height: 50px;
  object-fit: contain;
}

input[type='number'] {
  width: 40px;
  padding: 4px 5px;
  border: 1px solid #bbb;
  border-radius: 3px;
}

/* input[type="range"]:focus,
  input[type="number"]:focus {
    box-shadow: 0 0 3px 1px #4b81dd;
    outline: none;
  } */

input[type='range'] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 12px;
  background: rgba(255, 255, 255, 0.6);
  border-radius: 5px;
  background-image: linear-gradient(#7a53bf, #ed2c94);
  background-size: 70% 100%;
  background-repeat: no-repeat;
}

/* Input Thumb */
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background: #e0e0df;
  border: 4px solid #6600c9;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type='range']::-moz-range-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #6600c9;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

.customInputTwo {
  border: 'none';
  border-bottom: 3px solid #6600c9;
  background-color: transparent;
  outline: 'none';
}

input.customInputTwo:active,
input.customInputTwo:focus {
  outline: 0 solid #6600c9 !important;
}

input[type='range']::-ms-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #6600c9;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: background 0.3s ease-in-out;
}

input[type='range']::-webkit-slider-thumb:hover {
  background: #ff0200;
}

input[type='range']::-moz-range-thumb:hover {
  background: #ff0200;
}

input[type='range']::-ms-thumb:hover {
  background: #ff0200;
}

/* Input Track */
input[type='range']::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type='range']::-moz-range-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

input[type='range']::-ms-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}

.PricingPlans {
  .widthOneFive {
    width: 19.2%;
  }
  .widthOne {
    width: 15.35%;
  }
}

.customInput {
  border: 1px solid #6600c9;
  border-radius: 0.25rem;
}

input.customInput:active,
input.customInput:focus,
textarea.customInput:active,
textarea.customInput:focus {
  outline: 2px solid #6600c9;
  border-radius: 0.25rem;
}

.react-datepicker-time__input-container {
  outline: 2px solid #6600c9;
  border-radius: 0.25rem;
  margin-left: 10px;

  .react-datepicker-time__input {
    outline: none;
  }
}

.radio {
  margin: 0.5rem;
  input[type='radio'] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        background: #f4f4f4;
        border-radius: 100%;
        border: 1px solid darken(#f4f4f4, 25%);
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        top: -0.2em;
        margin-right: 0.75em;
        margin-top: 0.3rem;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: #6600c9;
          box-shadow: inset 0 0 0 4px #f4f4f4;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: #6600c9;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px #f4f4f4;
          border-color: darken(#f4f4f4, 25%);
          background: darken(#f4f4f4, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.message-input {
  textarea {
    height: 100px;
    overflow-y: scroll;
  }
}

@media screen and (max-width: 750px) {
  .slick-dots {
    top: 500px; // play with the number of pixels to position it as you want
  }
}

@media (min-width: 750) and (max-width: 1500px) {
  .slick-dots {
    top: 450px; // play with the number of pixels to position it as you want
  }
}

// .slick-dots {
//   top: 450px; // play with the number of pixels to position it as you want
// }

.slick-dots li.slick-active button:before {
  color: #6600c9 !important;
}

.slick-dots li button:before {
  font-size: 90px !important;
  content: '-' !important;
}

.react-switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.react-switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 50px;
  height: 31px;
  background: #fff;
  border-radius: 50px;
  position: relative;
  border: 2px solid #efefef;
  transition: background-color 0.2s;
  box-shadow: -1px 4px 4px 2px rgba(0, 0, 0, 0.11);
}

.react-switch-label .react-switch-button {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  width: 19px;
  height: 19px;
  border-radius: 19px;
  transition: 0.2s;
  background: #6600c9;

  box-shadow: -1px 4px 4px 2px rgba(0, 0, 0, 0.11);
}

.react-switch-checkbox:checked + .react-switch-label .react-switch-button {
  left: calc(100% - 4px);
  transform: translateX(-100%);
}

.react-switch-label:active .react-switch-button {
  width: 28px;
}

.rtf--mb {
  > * {
    transform-origin: center center;
    transform: none !important;
    transition: none !important;
  }
}

.rtf--mb__c {
  padding: 25px 0 !important;
}

.htmlContent {
  color: #374151;

  font-size: 1rem;
  line-height: 1.75;
  font-family: 'Raleway', 'sans-serif' !important;
}

.rc-slider-mark-text {
  top: 6px !important;
}

.tooltip {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  font-size: 13px;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
.customCheckbox {
  input[type='checkbox'] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    font-size: 14px;
    padding: 0px;
    outline: none;
    border: 1px solid #6600c9;
  }
  input[type='checkbox']:after {
    content: ' ';
    background-color: #6600c9;
    border-radius: 2px;
    display: inline-flex;
    justify-content: center;
    justify-items: center;
    visibility: visible;
    color: white;
    outline: none;
    margin-left: 0;
  }
  input[type='checkbox']:checked:after {
    content: '\2714';
    width: 20px;
    height: 20px;
    outline: none;
  }
  input[type='checkbox']:focus {
    outline: none;
  }
}
